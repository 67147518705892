
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import ForEditor from '@/components/for-editor';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { check_payslip_bulk_progress } from '@/api/cerp-model';
import PageModel4 from '@/components/page-model4/index.vue';
import { useI18n } from 'vue-i18n';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getMonth } from '@/utils/function';

export default defineComponent({
  components: {
    ForEditor,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PageModel4,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const img_a = reactive({img:require('@/assets/user/jidu.svg')});
    const img_b = reactive({img:require('@/assets/user/gongzijindu.svg')});
    const img_c = reactive({img:require('@/assets/user/gongzijindu1.svg')});
    const img_d = reactive({img:require('@/assets/user/gongzijindu2.svg')});
    const img_e = reactive({img:require('@/assets/user/gongzijindu3.svg')});
    const img_f = reactive({img:require('@/assets/user/gongzijindu4.svg')});
    const img_g = reactive({img:require('@/assets/user/gongzijindu5.svg')});
    const img_h = reactive({img:require('@/assets/user/gongzijindu6.svg')});
    const { t } = useI18n();
    const state:any = reactive({
      steps: [
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
        {
          desc: '',
        },
      ]
    })
    const current_org = store.getters['user/current_org'];
    const adviser = computed(() => store.getters[`user/adviser`]);
    const current_company = computed(() => store.getters['user/current_company']);
    const disabled: any = ref(false);
    const disabled_adviser: any = ref(true);
    const activeKey: any = ref('1');
    const service_month: any = ref(null);
    const checked: any = ref(false);
    const cc_company: any = ref('');
    const tags_list = ref([
      {
        key: '1',
        tab1: t('page.cerp.payslip.wages'),
        slots: { tab: 'tagsList' },
      },
      {
        key: '4',
        tab1: t('page.cerp.payslip.work_overtime'),
        slots: { tab: 'tagsList' },
      },
      {
        key: '2',
        tab1: t('page.cerp.payslip.Reimbursement'),
        slots: {tab: 'tagsList'},
      },
    ])
    const search = reactive({
      current: 1,
      pageSize: 10,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        cc_company_id: current_company.value?.id,
        record: {},
        is_active: true,
        invoice_type: '1',
        num: null,
      },
    });
    const scgyfangan = reactive({
      title: t('router.title.cerp-payslip'),
      column_flag: 'payslip',
      search: {...search, requestParams: {...search.requestParams}},
      modalType: 'cerp-payslip',
      height: 725,
      rowkey: 'invoice_id',
      mode: 'radio',
    });
    const getFangan = (record: any) => {
      service_month.value = getMonth(record.service_month);
      cc_company.value = record.cc_company__name;
      check_payslip_bulk_progress({
        org_id: current_org.id,
        invoice_ids: [record.invoice_id],
      }).then((res1: any) => {
        const f = res1.find((temp: any) => temp.invoice_id === record.invoice_id);
        if (f) {
          state.steps[0].desc = f.pay_time ? f.pay_time : '';
          state.steps[1].desc = f.customer_pay_time ? f.customer_pay_time : '';
          state.steps[2].desc = f.confirm_time ? f.confirm_time : '';
          state.steps[3].desc = f.send_time ? f.send_time : '';
          state.steps[4].desc = f.create_time ? f.create_time : '';
        }
      })
    };
    watch(() => current_company.value,
      () => {
        scgyfangan.search.requestParams.cc_company_id = current_company.value?.id;
        state.steps[0].desc = '';
        state.steps[1].desc = '';
        state.steps[2].desc = '';
        state.steps[3].desc = '';
        state.steps[4].desc = '';
        service_month.value = '';
      });
    const onTabChange = (value: string) => {
      activeKey.value = value;
      scgyfangan.search.requestParams.invoice_type = value;
      state.steps[0].desc = '';
      state.steps[1].desc = '';
      state.steps[2].desc = '';
      state.steps[3].desc = '';
      state.steps[4].desc = '';
      service_month.value = '';
    };
    return {
      t,
      tags_list,
      service_month,
      onTabChange,
      disabled_adviser,
      scgyfangan,
      state,
      activeKey,
      checked,
      getFangan,
      img_a,
      img_b,
      img_c,
      img_d,
      img_e,
      img_f,
      img_g,
      img_h,
      cc_company,
    };
  },
});
